const _defaultWatchlist = [
  '###Forex',
  'EURUSD',
  'GBPUSD',
  '###Indices',
  'DJCUSD.c',
  'NACUSD.c',
  '###Commodity',
  'XAUUSD',
  'XAGUSD',
  '###Futures',
  'NGASOCT24',
  'DXDEC24',
  '###Crypto',
  'BTCUSD.nx',
  'ADAUSD.nx',
  '###US Stocks',
  'AAPL',
  'MSFT',
  '###UK Stocks',
  'BARC.uk',
  'LLOY.uk',
  '###EU Stocks',
  'AALB.nl',
  'ADS.de',
  '###HK Stocks',
  '1.xhkg',
  '1024.xhkg',
];

function getParameterByName(name) {
  let val = window.localStorage.getItem(name);
  if (!val && name === 'theme') {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    )
      return 'dark';
    else return 'light';
  }
  return val;
}

if (!window.localStorage.getItem('initialSettings_updated')) {
  if (
    window.localStorage.getItem('watchlists') ||
    window.localStorage.getItem('charts')
  ) {
    window.localStorage.removeItem('watchlists');
    window.localStorage.removeItem('watchlist-active');
    window.localStorage.removeItem('charts');
    setTimeout(() => {
      window.showInformModal();
    }, 100);
  }
  window.localStorage.setItem('initialSettings_updated', true);
}

const datafeed = new LuxTVDataFeed.LuxDataFeed();
let initialSettings = JSON.parse(
  window.localStorage.getItem('initialSettings'),
);
if (initialSettings) {
  const initialSettingsKeys = Object.keys(initialSettings);
  let defaultWatchlistsFaound = false;
  for (let key of initialSettingsKeys) {
    if (key.startsWith('savedwatch')) {
      if (JSON.parse(initialSettings[key]).title === 'Watchlist') {
        initialSettings[key] = JSON.stringify({
          symbols: _defaultWatchlist,
          title: 'Watchlist',
        });
        defaultWatchlistsFaound = true;
      }
    }
  }
  if (!defaultWatchlistsFaound) {
    initialSettings['savedwatch.MYdefaultWAT'] = JSON.stringify({
      symbols: _defaultWatchlist,
      title: 'Watchlist',
    });
  }
}

let widget = (window.tvWidget = new TradingView.widget({
  // debug: true,
  symbol: 'EURUSD', // Default symbol pair
  interval: '15', // Default interval
  fullscreen: true, // Displays the chart in the fullscreen mode
  container: 'tv_chart_container', // Reference to the attribute of the DOM element
  datafeed: datafeed,
  library_path: './charting_library/',
  locale: 'en',
  enabled_features: [
    'dom_widget',
    'show_symbol_logos',
    'display_market_status',
    'popup_hints',
    'seconds_resolution',
    'tick_resolution',
    'trading_notifications',
    'show_trading_notifications_history',
  ],
  disabled_features: ['uppercase_instrument_names'],
  custom_css_url: '../styles.css?time=1711756800',
  charts_storage_api_version: '1.1',
  client_id: 'trading_platform_demo',
  user_id: 'public_user',
  theme: getParameterByName('theme'),
  overrides: {
    'tradingProperties.extendLeft': false,
    'tradingProperties.horizontalAlignment': 1,
  },
  widgetbar: {
    details: true,
    news: false,
    watchlist: true,
    datawindow: true,
    watchlist_settings: {
      default_symbols: _defaultWatchlist,
      readonly: false,
    },
  },
  rss_news_feed: {
    default: [
      {
        url: 'https://demo-feed-data.tradingview.com/news?symbol={SYMBOL}',
        name: 'Yahoo Finance',
      },
    ],
  },
  news_provider: function getNews(symbol, callback) {
    callback({
      title: 'This is the title!',
      newsItems: [
        /* ... */
      ],
    });
  },
  load_last_chart: true,
  save_load_adapter: {
    charts: [],
    studyTemplates: [],
    drawingTemplates: [],
    chartTemplates: [],

    getAllCharts: function () {
      let charts = window.localStorage.getItem('charts');
      if (charts) {
        charts = JSON.parse(charts);
      } else {
        charts = [];
      }
      return Promise.resolve(charts);
    },

    removeChart: async function (id) {
      let charts = await this.getAllCharts();
      for (var i = 0; i < charts.length; ++i) {
        if (charts[i].id === id) {
          charts.splice(i, 1);
          window.localStorage.setItem('charts', JSON.stringify(charts));
          return Promise.resolve();
        }
      }
      return Promise.reject();
    },

    saveChart: async function (chartData) {
      if (!chartData.id) {
        chartData.id = Math.random().toString();
      } else {
        await this.removeChart(chartData.id);
      }
      chartData.timestamp = moment().unix();
      let charts = await this.getAllCharts();
      charts.push(chartData);
      window.localStorage.setItem('charts', JSON.stringify(charts));
      return Promise.resolve(chartData.id);
    },

    getChartContent: async function (id) {
      let charts = await this.getAllCharts();
      for (var i = 0; i < charts.length; ++i) {
        if (charts[i].id === id) {
          return Promise.resolve(charts[i].content);
        }
      }
      return Promise.reject();
    },

    // drawing templates related methods
    getAllDrawingTemplates: function () {
      let drawings = window.localStorage.getItem('drawingTemplates');
      if (drawings) {
        drawings = JSON.parse(drawings);
      } else {
        drawings = {};
      }
      return Promise.resolve(drawings);
    },
    saveDrawingTemplate: async function (toolName, templateName, content) {
      let drawings = await this.getAllDrawingTemplates();
      if (drawings[toolName]) {
        const templateIndex = drawings[toolName].findIndex(
          (template) => template.templateName === templateName,
        );
        if (templateIndex !== -1) {
          // Update the existing template
          drawings[toolName][templateIndex].content = content;
        } else {
          drawings[toolName].push({
            templateName,
            content,
          });
        }
      } else {
        drawings[toolName] = [
          {
            templateName,
            content,
          },
        ];
      }
      window.localStorage.setItem('drawingTemplates', JSON.stringify(drawings));
      return Promise.resolve(toolName);
    },
    getDrawingTemplates: async function (toolName) {
      let drawings = await this.getAllDrawingTemplates();
      if (drawings[toolName]) {
        const templateNames = drawings[toolName].map(
          (template) => template.templateName,
        );
        return Promise.resolve(templateNames);
      }
      return Promise.resolve([]);
    },
    loadDrawingTemplate: async function (toolName, templateName) {
      let drawings = await this.getAllDrawingTemplates();
      if (drawings[toolName]) {
        const template = drawings[toolName].find(
          (template) => template.templateName === templateName,
        );
        if (template) {
          return Promise.resolve(template.content);
        }
      }
      return Promise.reject();
    },
    removeDrawingTemplate: async function (toolName, templateName) {
      let drawings = await this.getAllDrawingTemplates();
      if (drawings[toolName]) {
        const templateIndex = drawings[toolName].findIndex(
          (template) => template.templateName === templateName,
        );
        if (templateIndex !== -1) {
          drawings[toolName].splice(templateIndex, 1);
          if (drawings[toolName].length === 0) {
            delete drawings[toolName];
          }
          window.localStorage.setItem(
            'drawingTemplates',
            JSON.stringify(drawings),
          );
          return Promise.resolve();
        }
      }
      return Promise.reject();
    },
  },

  settings_adapter: {
    loadSettings: function (key, value) {
      if (!window.localStorage.getItem('initialSettings')) {
        window.localStorage.setItem(
          'initialSettings',
          JSON.stringify({
            symbolWatermark:
              '{ "visibility": "true", "color": "rgba(75,79,94,0.25)" }',
          }),
        );
        initialSettings = JSON.parse(
          window.localStorage.getItem('initialSettings'),
        );
      }
      if (key.startsWith('savedwatch')) {
        if (JSON.parse(value).title === 'Watchlist') {
          window.broker?._host?.showNotification(
            'Watchlist alert',
            'This is default watch list. It will reset on page reload. You should copy and keep your custom watchlist.',
            0,
          );
        }
      }
      initialSettings[key] = value;
      window.localStorage.setItem(
        'initialSettings',
        JSON.stringify(initialSettings),
      );
    },
    initialSettings: initialSettings,
    setValue: function (key, value) {
      this.loadSettings(key, value);
    },
    removeValue: function (key) {
      initialSettings = JSON.parse(
        window.localStorage.getItem('initialSettings'),
      );
      delete initialSettings[key];
      window.localStorage.setItem(
        'initialSettings',
        JSON.stringify(initialSettings),
      );
    },
  },

  broker_factory: function (host) {
    const brokerInstance = new LuxTVBroker.LuxBroker(host, datafeed);
    window.broker = brokerInstance;
    return brokerInstance;
  },
  broker_config: {
    configFlags: {
      calculatePLUsingLast: true,
      showNotificationsLog: true,
      supportAddBracketsToExistingOrder: true,
      supportClosePosition: true,
      supportConfirmations: true,
      supportEditAmount: false,
      supportExecutions: true,
      supportLeverage: false,
      supportMarketBrackets: true,
      supportModifyBrackets: true,
      supportModifyTrailingStop: false,
      supportOrderBrackets: true,
      supportOrdersHistory: true,
      supportPartialClosePosition: true,
      supportPLUpdate: true,
      supportPositions: true,
      supportPositionBrackets: true,
      supportReversePosition: false,
      supportStopLimitOrders: false,
    },
    durations: [
      { name: 'DAY', value: 'DAY' },
      { name: 'GTC', value: 'GTC' },
    ],
    positionsAlignment: 'Center',
    ordersAlignment: 'Center',
  },
}));

widget.headerReady().then(async () => {
  widget.createDropdown({
    title: 'Toggle Theme',
    align: 'right',
    items: [
      {
        title: 'Dark',
        onSelect: () => {
          widget.changeTheme('dark');
          window.localStorage.setItem('theme', 'dark');
        },
      },
      {
        title: 'Light',
        onSelect: () => {
          widget.changeTheme('light');
          window.localStorage.setItem('theme', 'light');
        },
      },
    ],
  });
  const timezoneApi = await widget.chart().getTimezoneApi();
  const timezone = timezoneApi.getTimezone();
  window.broker._currentUtcTimezone = timezone.id;
  const updateCurrentUtcTimezone = (timezone) => {
    window.broker._currentUtcTimezone = timezone;
  };
  timezoneApi.onTimezoneChanged().subscribe(null, updateCurrentUtcTimezone);
});
widget.onChartReady(async () => {
  if (!broker.userLoggedIn) {
    broker._host._trading.toggleMinimizeBottomWidgetBar();
  }
});
